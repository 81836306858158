import {
  List,
  TextField,
  Datagrid,
  DateField,
  ReferenceField,
  RichTextField,
} from "react-admin";
import { Box } from "@mui/material";
import StoreOrders from "./StoreOrders";


const StoreList = () => {
  return (
    <Box display="flex">
      <List
        perPage={25}
        // pagination={false}
        component="div"
        actions={false}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(["all"], {
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        <Datagrid
          rowClick="expand"
          isRowSelectable={() => false}
          bulkActionButtons={false}
          expand={<StoreOrders />}
          sx={{
            '& .RaDatagrid-root':{
              margin: '0px 0px 20px 30px',
              backgroundColor: '#d7f6ff'
            }
          }}
        >
          <TextField source="id"/>
          <TextField source="full_name" />
          <ReferenceField source="user_id" sortable={false}  reference="stores/users">
                    <TextField source="email" />
                </ReferenceField>
          <TextField source="username" label="Store Name"/>
          <TextField source="story" sortable={false}/>
          <ReferenceField source="user_id" sortable={false} reference="stores/interests" label="Interests">
            <TextField source="interests" />
          </ReferenceField>
          <ReferenceField sortable={false} source="id" reference="stores/socials" label="Social Handles">
            <RichTextField source="socials" />
          </ReferenceField>
          <DateField source="created_at" sortable={false} />
          <TextField source="is_publish" sortable={false} />
          <DateField source="publish_time"/>
        </Datagrid>
      </List>
    </Box>
  );
};
 

export default StoreList;
