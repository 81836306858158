import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  DateInput,
  SelectInput,
  
} from "react-admin";

import { Rs_Status } from "./rs_status";
import StatusButton from "./StatusButton";

const listFilters = [
  <DateInput source="date_gte" label="Date from" alwaysOn />,
  <DateInput source="date_lte" label="Date to" alwaysOn />,
  <SelectInput label="Status" source="status" alwaysOn choices={Rs_Status} />,
  // <TextInput source="store" alwaysOn />,
];

const ActionButtons = () => (
  <div className="rq_toolbar">
    <StatusButton />
  </div>
);

// const exporter = (samples: any) => {
//   // console.dir(samples);
//   const selectedIds = samples.map((sample: RaRecord) => sample.id);
//   sample_exporter(selectedIds);
// };

const RequestList = () => {
  return (
    <List
      filters={listFilters}
      perPage={25}
      resource="partnership_applied"
      sort={{ field: "updated_at", order: "desc" }}
     // exporter={exporter}
    >
      <Datagrid
        // rowClick='edit'
        bulkActionButtons={<ActionButtons />}
        sx={{
          "& .brand": {
            fontWeight: 700,
            marginRight: "20px",
          },
          "& .rq_toolbar": {
            display: "flex",
          },
        }}
      >
        <TextField source="id" />
        <ReferenceField source="store_id" reference="stores">
          <TextField source="username" label="Store" />
        </ReferenceField>
        <ReferenceField source="ps_id" reference="partnership" label="Partnership">
          <TextField source="title" label="Partnership" />
        </ReferenceField>
        <DateField source="updated_at" label="Date" />
        <TextField source="goodfit" />
        <TextField source="niche" />
        <TextField source="city" />
        <TextField source="province" />
        <TextField source="country" />
        <TextField source="phone" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};

export default RequestList;
